import { Environment } from '@shared/tokens/environment';

export const environment: Environment = {
	name: 'production',
	production: true,
	version: 'craftnote-web@a564586af648a13b2f8e9238477904c1bb4d865f',
	baseUrl: 'https://europe-west1-craftnote-live.cloudfunctions.net/',
	appUrl: 'https://app.mycraftnote.de/#/',
	app_dynamic_link: 'https://mycraftnote.page.link/3AbM',
	firebaseConfig: {
		apiKey: 'AIzaSyAmMTLiLtT9xb_bhFqwYGH61USo-z9iI-k',
		authDomain: 'craftnote-live.firebaseapp.com',
		databaseURL: 'https://craftnote-live.firebaseio.com',
		projectId: 'craftnote-live',
		storageBucket: 'craftnote-live.appspot.com',
		messagingSenderId: '206813385858',
		appId: '1:206813385858:web:370e6108e27f05ac05909b',
		measurementId: 'G-LBHGJXMR09',
	},
	stripe: {
		public_key: 'pk_live_245TXSd28lkcSjbxi1rfZ0Uv',
	},
	company_data_bucket: 'craftnote-live-company-data',
	export_data_bucket: 'craftnote-live-exports',
	company_website_url: 'https://profi.craftnote.de',
	planning_board_url: 'https://planning-board-live.web.app',
	external_chat_url: 'https://external-chat-live.web.app',
	public_folders_bucket_name: 'craftnote-live-public-folders',
	zendesk_feedback_button_id: 'a5d1c917-6a97-4af1-9429-6d167e843325',
	appBaseUrl: 'https://app.mycraftnote.de',
	publicAssetsBucket: 'craftnote-live-public-assets',
	loopedIn: {
		workspace_id: '8c1b18ba-09b9-4773-97f2-32f072c0aa6b',
	},
	pspdfkit: {
		license_key:
			'lfz3D_EBInszyB1BJe4wiM7b1WnFrAOrZXrFK9o11csXvZQ8LG816eu7AJt-bdSUiGy5plY4AXVyEuEytYk0ayJ7WgnDEYIt6xW6Jg6v4h2FjHT8fR6qexqg8hf1uY9JO5ZLbJ_xpHUT-sTx3AFqdvW52qtsldHpnTn7z4-SXsMFjRg54tQTt1Gl_88lx5W4VHAG27byI7urmAXnNudVxT5BMif_oW5wX6v6ZaKJD9bqKipyFmD-Cp5zH1E6VL8oFWCBAH4cbePng7i_yS6e4XYQCYbSObrIMeZd7-amHm_wTTBLyGswCioJhC7z9o5gRxqO9uNOndtNf58SekjxsQB7Y-LdiMLPJeLR0fzRyuP-oZx0_FY9KDRBmDN702btAMD4rPNwrOgvjEbFvcJ1QKMVscU1kdKbMAyXB-EH6Z4BfS6uc4IcGP2BdBSMi6oli2f7AfonlrtsOdJqrA1bUtbSbqt0PNPfGxGYdUP0IufGcJXTLK4O-QvQYSvmNJA5at2PGDNEdargICfbWJ3YEQP5zJSCGqgTu5AMYLlOMhQmpHCzIycJnBcEbOyWKFX3',
	},
	globalSearchElasticSearchUrl: 'https://craftnote-search-api-service-ozccfr4v3q-ey.a.run.app',
	restApiUrl: 'https://api-kjzbsdf.bff.web.craftnote.cloud',
	realtimeApiUrl: 'https://socket-kjzbsdf.bff.web.craftnote.cloud',
	frontendComponents: {
		scripts: {
			pricingTable:
				'https://web.cdn.craftnote.cloud/pricing-table/live/pricing-table/pricing-table.esm.js',
			subscriptionCheckout:
				'https://web.cdn.craftnote.cloud/subscription-checkout/live/subscription-checkout/subscription-checkout.esm.js',
			licenseAllocation:
				'https://web.cdn.craftnote.cloud/license-allocation/live/license-allocation/license-allocation.esm.js',
			licenseManagement:
				'https://web.cdn.craftnote.cloud/license-management/live/license-management/license-management.esm.js',
		},
	},
};
